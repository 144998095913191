<template>

    <!-- 編集 -->
    <section v-if="is_edit" class="section pb-5">
        <div class="section-title">
            <h2>案件情報</h2>
            <div>
                <button @click="is_edit = false; changeIsEdit(false);" type="button" class="btn btn-outline-secondary me-3"><i class="bi bi-x"></i> キャンセル</button>
                <button form="update_project_form" type="submit" class="btn btn-info"><i class="bi bi-save"></i> 保存</button>
            </div>
        </div>

        <form @submit.prevent="save" id="update_project_form">
            <h3 class="mb-3 mt-5"><i class="bi bi-caret-right-fill text-primary"></i> 基本情報</h3>
            <div>
                <div class="row mb-3">
                    <div class="col-9">
                        <label class="form-label">事業部 <i class="required"></i></label>
                        <form-select-object
                            v-model="edit_project.department"
                            :options="options_department"
                            option_value="department_id"
                            option_label="department_name"
                            @selected="changeDepartment()"
                            :required="true"
                        ></form-select-object>
                    </div>
                    <div class="col-9">
                        <label class="form-label">大手企業 <small>(顧客情報)</small></label>
                        <form-checkbox
                            v-model="edit_project.customer.is_major"
                            option_label="大手企業"
                        ></form-checkbox>
                    </div>
                </div>
                <div class="row mb-3 align-items-end">
                    <div class="col-9">
                        <label class="form-label">反響対応者</label>
                        <form-select-search
                            v-model="edit_project.contact_user.user_id"
                            :options="options_user"
                            placeholder="----"
                        ></form-select-search>
                    </div>
                    <div class="col-9">
                        <label class="form-label">追客担当</label>
                        <form-select-search
                            v-model="edit_project.follow_user.user_id"
                            :options="options_user"
                            placeholder="----"
                        ></form-select-search>
                    </div>
                    <div class="col-9">
                        <label class="form-label">営業担当</label>
                        <form-select-search
                            v-model="edit_project.sales_user.user_id"
                            :options="options_user"
                            placeholder="----"
                        ></form-select-search>
                    </div>
                </div>
                <div class="row mb-3 align-items-end">
                    <div class="col-9">
                        <label class="form-label">ステータス</label>
                        <form-select
                            v-model="edit_project.status"
                            :options="Status.options()"
                        ></form-select>
                    </div>
                    <div class="col-9">
                        <form-checkbox
                            v-model="edit_project.no_follow"
                            option_label="追客なし"
                        ></form-checkbox>
                    </div>
                    <div class="col-9">
                        <label class="form-label">優先度</label>
                        <form-select-object
                            v-model="edit_project.probability"
                            :options="probabilities"
                            option_value="probability_id"
                            option_label="probability_name"
                            empty_option="----"
                            :disabled="is_disabled"
                        ></form-select-object>
                    </div>
                    <div class="col-9">
                        <label class="form-label">評価</label>
                        <form-select
                            v-model="edit_project.rank"
                            :options="Rank.options()"
                            empty_option="----"
                        ></form-select>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-9">
                        <label class="form-label">サブ評価</label>
                        <form-select-object
                            v-model="edit_project.detailed_rank"
                            :options="detailed_ranks"
                            option_value="detailed_rank_id"
                            option_label="detailed_rank_name"
                            empty_option="----"
                            :disabled="is_disabled"
                        ></form-select-object>
                    </div>
                    <div class="col-9">
                        <label class="form-label">確実度</label>
                        <form-select-object
                            v-model="edit_project.certainty"
                            :options="certainties"
                            option_value="certainty_id"
                            option_label="certainty_name"
                            empty_option="----"
                            :disabled="is_disabled"
                        ></form-select-object>
                    </div>
                    <div class="col-9">
                        <label class="form-label">計測カウント</label>
                        <form-select
                            v-model="edit_project.count_type"
                            :options="CountType.options()"
                        ></form-select>
                    </div>
                    <div class="col-9">
                        <label class="form-label">認証有効期限日</label>
                        <form-input-date v-model="edit_project.certification_expiry_date"></form-input-date>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-9">
                        <label class="form-label">契約形態</label>
                        <form-select
                            v-model="edit_project.contract_type"
                            :options="ContractType.options()"
                            empty_option="----"
                        ></form-select>
                    </div>
                </div>
            </div>

            <h3 class="mb-3 mt-5"><i class="bi bi-caret-right-fill text-primary"></i> 追客状況</h3>
            <div>
                <div class="row mb-3">
                    <div class="col-9">
                        <label class="form-label">次回アプローチ日</label>
                        <form-input-date v-model="edit_project.next_approaching_date"></form-input-date>
                    </div>
                    <div class="col-9">
                        <label class="form-label">見込日</label>
                        <form-input-date v-model="edit_project.expected_order_date"></form-input-date>
                    </div>
                    <div class="col-9">
                        <label class="form-label"></label>
                        <form-checkbox
                            v-model="edit_project.is_rescheduling"
                            option_label="リスケ"
                        ></form-checkbox>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-36 mb-3">
                        <div class="col">
                            <label class="form-label">追客計画</label>
                            <form-textarea v-model="edit_project.follow_plan"></form-textarea>
                        </div>
                    </div>
                </div>
            </div>

            <h3 class="mb-3 mt-5"><i class="bi bi-caret-right-fill text-primary"></i> 反響情報</h3>
            <div>
                <div class="row mb-3">
                    <div class="col-9">
                        <label class="form-label">反響日 <i class="required"></i></label>
                        <form-input-date v-model="edit_project.contact_date" :required="true"></form-input-date>
                    </div>
                    <div class="col-9">
                        <label class="form-label">紹介元（コンサル以外）</label>
                        <form-input v-model="edit_project.referrer"></form-input>
                    </div>
                    <div class="col-9">
                        <label class="form-label">チャネル <i class="required"></i></label>
                        <form-select-object-search
                            v-model="edit_project.channel"
                            :options="options_channel"
                            option_value="channel_id"
                            option_label="channel_name"
                            :required="true"
                        ></form-select-object-search>
                    </div>
                    <div class="col-9">
                        <label class="form-label">キャンペーンNo</label>
                        <form-input v-model="edit_project.campaign_number"></form-input>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col">
                        <label class="form-label">反響備考</label>
                        <form-textarea v-model="edit_project.contact_note"></form-textarea>
                    </div>
                </div>
            </div>

            <h3 class="mb-3 mt-5"><i class="bi bi-caret-right-fill text-primary"></i> コンサル紹介情報</h3>
            <div>
                <div class="row mb-3">
                    <div class="col-9">
                        <label class="form-label">紹介元（お客様名）</label>
                        <form-input v-model="edit_project.referrer_company"></form-input>
                    </div>
                    <div class="col-9">
                        <label class="form-label">担当コンサル</label>
                        <form-select-object-search
                            v-model="edit_project.referrer_company_consultant_user"
                            :options="options_user_object"
                            option_value="user_id"
                            option_label="user_name"
                        ></form-select-object-search>
                    </div>
                </div>
            </div>

            <h3 class="mb-3 mt-5"><i class="bi bi-caret-right-fill text-primary"></i> 客先担当者</h3>
            <div>
                <template v-for="contact in edit_project.contacts" :key="contact.edit_project_contact_id">
                    <div class="row mb-5">
                        <div class="col-33">
                            <div class="row mb-2">
                                <div class="col-9">
                                    <label class="form-label">氏名 <i class="required"></i></label>
                                    <form-input v-model="contact.contact_name" :required="true"></form-input>
                                </div>
                                <div class="col-9">
                                    <label class="form-label">フリガナ</label>
                                    <form-input v-model="contact.contact_name_kana"></form-input>
                                </div>
                                <div class="col-9">
                                    <label class="form-label">役職</label>
                                    <div class="input-group">
                                        <form-checkbox v-model="contact.is_ceo" option_label="代表" style="white-space: nowrap;"></form-checkbox>
                                        <form-input v-model="contact.position" style="flex-grow: 2;"></form-input>
                                    </div>
                                </div>
                                <div class="col-9">
                                    <label class="form-label">部署</label>
                                    <form-input v-model="contact.division"></form-input>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-9">
                                    <label class="form-label">携帯</label>
                                    <form-input v-model="contact.phone"></form-input>
                                </div>
                                <div class="col-18">
                                    <label class="form-label">メール</label>
                                    <form-input v-model="contact.email"></form-input>
                                </div>
                                <div class="col-9">
                                    <label class="form-label">連絡先種別</label>
                                    <form-select
                                        v-model="contact.contact_type"
                                        :options="ContactType.options()"
                                    ></form-select>
                                </div>
                            </div>
                        </div>
                        <div class="col-3 align-items-center justify-content-center">
                            <button @click="removeContact(contact.edit_project_contact_id)" class="btn btn-outline-danger"><i class="bi bi-trash"></i></button>
                        </div>
                    </div>
                </template>
                <button @click="addContact()" type="button" class="btn btn-outline-primary"><i class="bi bi-plus"></i> 担当者を追加</button>
            </div>

            <h3 class="mb-3 mt-5"><i class="bi bi-caret-right-fill text-primary"></i> 住所</h3>
            <div>
                <div class="row mb-3">
                    <div class="col-18">
                        <form-input v-model="address_to_parse" placeholder="住所・会社名等を入力"></form-input>
                    </div>
                    <div class="col-4">
                        <button type="button" class="btn btn-outline-primary" @click="parseAddress">取得</button>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-9">
                        <label class="form-label">郵便番号</label>
                        <div class="input-group">
                            <span class="input-group-text">〒</span>
                            <form-input v-model="edit_project.zip_code" @change="searchAddressByZip()"></form-input>
                        </div>
                    </div>
                    <div class="col-9">
                        <label class="form-label">都道府県</label>
                        <form-select-object-search
                            v-model="edit_project.area"
                            :options="options_area"
                            option_value="area_id"
                            option_label="area_name"
                        ></form-select-object-search>
                    </div>
                    <div class="col-9">
                        <label class="form-label">市区郡</label>
                        <form-input v-model="edit_project.city"></form-input>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-27">
                        <label class="form-label">町名・番地</label>
                        <form-input v-model="edit_project.street"></form-input>
                    </div>
                    <div class="col-9">
                        <label class="form-label">最寄駅</label>
                        <form-input v-model="edit_project.closest_station"></form-input>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-18">
                        <label class="form-label">Google Maps</label>
                        <form-input v-model="edit_project.google_map"></form-input>
                    </div>
                </div>
            </div>

            <h3 class="mb-3 mt-5"><i class="bi bi-caret-right-fill text-primary"></i> アポイント情報</h3>
            <div>
                <div class="row mb-3">
                    <div class="col-9">
                        <label class="form-label">初回アポイント日時</label>
                        <div class="form-readonly">{{ $helper.ymdhi(project.first_appointment_datetime) }}</div>
                    </div>
                    <div class="col-9">
                        <label class="form-label">最新アポイント日時</label>
                        <div class="form-readonly">{{ $helper.ymdhi(project.latest_appointment_datetime) }}</div>
                    </div>
                    <div class="col-9">
                        <label class="form-label">初回アポ取得日</label>
                        <div class="form-readonly">{{ $helper.ymd(project.first_making_appointment_date) }}</div>
                    </div>
                    <div class="col-9">
                        <label class="form-label">最新アポ取得日</label>
                        <div class="form-readonly">{{ $helper.ymd(project.latest_making_appointment_date) }}</div>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col">
                        <label class="form-label">アポイント備考</label>
                        <form-textarea v-model="edit_project.appointment_note"></form-textarea>
                    </div>
                </div>
            </div>

            <h3 class="mb-3 mt-5"><i class="bi bi-caret-right-fill text-primary"></i> 営業報告</h3>
            <div>
                <div class="row mb-3">
                    <div class="col-18">
                        <label class="form-label">競合</label>
                        <form-select-object-search-multiple
                            v-model="edit_project.competitors_entities"
                            :options="competitors"
                            option_value="competitor_id"
                            option_label="competitor_name"
                        ></form-select-object-search-multiple>
                    </div>
                    <div class="col-18">
                        <label class="form-label">候補落ち競合</label>
                        <form-select-object-search-multiple
                            v-model="edit_project.lost_competitors_entities"
                            :options="competitors"
                            option_value="competitor_id"
                            option_label="competitor_name"
                        ></form-select-object-search-multiple>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-18">
                        <label class="form-label">競合（その他）</label>
                        <form-input v-model="edit_project.competitor_note"></form-input>
                    </div>
                    <div class="col-9">
                        <label class="form-label">取得理由</label>
                        <form-select
                            v-model="edit_project.purpose"
                            :options="Purpose.options()"
                            empty_option="----"
                        ></form-select>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-18 mb-3">
                        <label class="form-label">規格</label>
                        <form-select-search-multiple
                            v-model="edit_project.standards_values"
                            :options="Standard.options()"
                        ></form-select-search-multiple>
                    </div>
                    <div class="col-18">
                        <label class="form-label">その他規格・備考</label>
                        <form-input v-model="edit_project.standard_note"></form-input>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col">
                        <label class="form-label">注意事項</label>
                        <form-textarea v-model="edit_project.sales_note"></form-textarea>
                    </div>
                </div>
                <div class="row mb-5">
                    <div class="col">
                        <label class="form-label">担当コンサル選定時の注意事項</label>
                        <form-textarea v-model="edit_project.consultant_note"></form-textarea>
                    </div>
                </div>
                <div class="row mb-3 align-items-end">
                    <div v-if="edit_project.department?.use_proposal_price" class="col-9">
                        <label class="form-label">提案金額</label>
                        <div class="input-group">
                            <form-input-number v-model="edit_project.proposal_price"></form-input-number>
                            <span class="input-group-text">万円</span>
                        </div>
                    </div>
                    <div class="col-9">
                        <form-checkbox
                            v-model="edit_project.can_video_meeting"
                            option_label="ビデオ通話"
                        ></form-checkbox>
                    </div>
                </div>
            </div>

            <h3 class="mb-3 mt-5"><i class="bi bi-caret-right-fill text-primary"></i> Webフォームからの情報</h3>
            <div>
                <div class="row mb-3">
                    <div class="col-9">
                        <label class="form-label">メールマガジン購読</label>
                        <form-select
                            v-model="edit_project.subscribe_mail_magazine"
                            :options="SubscribeMailMagazine.options()"
                            empty_option="----"
                        ></form-select>
                    </div>
                    <div class="col-9">
                        <label class="form-label">送信元ページ</label>
                        <form-select-object-search
                            v-model="edit_project.detailed_channel"
                            :options="options_detailed_channel"
                            option_value="detailed_channel_id"
                            option_label="detailed_channel_name"
                        ></form-select-object-search>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-36 mb-3">
                        <label class="form-label">資料請求</label>
                        <form-textarea v-model="edit_project.form_document"></form-textarea>
                    </div>
                    <div class="col-36 mb-3">
                        <label class="form-label">ご検討事項</label>
                        <form-textarea v-model="edit_project.form_consideration"></form-textarea>
                    </div>
                    <div class="col-36 mb-3">
                        <label class="form-label">ご希望のサービス内容</label>
                        <form-textarea v-model="edit_project.form_request"></form-textarea>
                    </div>
                    <div class="col-36">
                        <label class="form-label">お問合せ内容／ご相談内容／ご相談・ご要望・希望日</label>
                        <form-textarea v-model="edit_project.form_inquiry"></form-textarea>
                    </div>
                </div>
            </div>

            <h3 class="mb-3 mt-5"><i class="bi bi-caret-right-fill text-primary"></i> 受注情報</h3>
            <div>
                <div class="row mb-3">
                    <!-- ステータスが受注のときのみ変更可能 -->
                    <div class="col-9">
                        <label class="form-label">受注日</label>
                        <form-input-date
                            v-model="edit_project.ordered_date"
                            :disabled="edit_project.status !== Status.STARTED"
                        ></form-input-date>
                    </div>
                    <div class="col-9">
                        <label class="form-label">受注理由</label>
                        <form-select
                            v-model="edit_project.ordered_reason"
                            :options="OrderedReason.options()"
                            empty_option="----"
                            :disabled="edit_project.status !== Status.STARTED"
                        ></form-select>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-36">
                        <label class="form-label">受注理由詳細</label>
                        <form-textarea
                            v-model="edit_project.ordered_reason_detail"
                            :disabled="edit_project.status !== Status.STARTED"
                        ></form-textarea>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-9">
                        <label class="form-label">顧客番号</label>
                        <form-input v-model="edit_project.client_number" :disabled="edit_project.status !== Status.STARTED"></form-input>
                    </div>
                    <div class="col-9">
                        <label class="form-label">契約金額</label>
                        <div class="input-group">
                            <form-input-number v-model="edit_project.contract_price" :disabled="edit_project.status !== Status.STARTED"></form-input-number>
                            <span class="input-group-text">円</span>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-9">
                        <label class="form-label">契約ステータス</label>
                        <form-select
                            v-model="edit_project.contract_status"
                            :options="ContractStatus.options()"
                            :disabled="edit_project.status !== Status.STARTED"
                        ></form-select>
                    </div>
                    <div class="col-9">
                        <label class="form-label">契約書原案送付日</label>
                        <form-input-date v-model="edit_project.contract_request_review_date" :disabled="edit_project.status !== Status.STARTED"></form-input-date>
                    </div>
                    <div class="col-9">
                        <label class="form-label">クラウドサイン送付日</label>
                        <form-input-date v-model="edit_project.contract_request_sign_date" :disabled="edit_project.status !== Status.STARTED"></form-input-date>
                    </div>
                    <div class="col-9">
                        <label class="form-label">契約締結完了日</label>
                        <form-input-date v-model="edit_project.contract_execute_date" :disabled="edit_project.status !== Status.STARTED"></form-input-date>
                    </div>
                </div>
            </div>

            <h3 class="mb-3 mt-5"><i class="bi bi-caret-right-fill text-primary"></i> 失注理由</h3>
            <div>
                <div class="row mb-3">
                    <div class="col-9 mb-3">
                        <label class="form-label">失注日</label>
                        <form-input-date
                            v-model="edit_project.lost_date"
                        ></form-input-date>
                    </div>
                    <div class="col-9 mb-3">
                        <label class="form-label">失注先</label>
                        <form-select-object-search
                            v-model="edit_project.lost_against_competitor"
                            :options="competitors"
                            option_value="competitor_id"
                            option_label="competitor_name"
                        ></form-select-object-search>
                    </div>
                    <div class="col-9 mb-3">
                        <label class="form-label">他社落ち理由</label>
                        <form-select
                            v-model="edit_project.lost_reason"
                            :options="LostReason.options()"
                            empty_option="----"
                        ></form-select>
                    </div>
                    <div class="col-36">
                        <label class="form-label">失注理由</label>
                        <form-textarea v-model="edit_project.lost_cause"></form-textarea>
                    </div>
                </div>
            </div>

            <h3 class="mb-3 mt-5"><i class="bi bi-caret-right-fill text-primary"></i> 過去の訪問・追跡履歴</h3>
            <div class="row mb-3">
                <div class="col-36">
                    <label class="form-label">過去の訪問・追跡履歴</label>
                    <form-textarea v-model="edit_project.history"></form-textarea>
                </div>
            </div>
        </form>
    </section>

    <!-- 閲覧 -->
    <section v-else class="section pb-5">
        <div class="section-title">
            <h2>案件情報</h2>
            <button v-if="$store.getters['auth/canEditProject']" type="button" @click="is_edit = true; changeIsEdit(true);" class="btn btn-outline-primary" :disabled="is_editing_header"><i class="bi bi-pencil-square"></i> 編集</button>
        </div>

        <h3 class="mb-3 mt-5">
            <i class="bi bi-caret-up-fill text-primary" :class="{'toggle-close': is_open.basic === false, 'toggle-open': is_open.basic === true,}"></i>
            <span @click="is_open.basic = !is_open.basic" role="button">基本情報</span>
        </h3>
        <transition>
            <div v-if="is_open.basic">
                <div class="row mb-3">
                    <div class="col-9">
                        <label class="form-label">事業部</label>
                        <div class="form-readonly">{{ project.department?.department_name }}</div>
                    </div>
                    <div class="col-9">
                        <label class="form-label">大手企業 <small>(顧客情報)</small></label>
                        <div class="form-readonly">
                            <i v-if="project.customer.is_major === IsMajor.YES" class="bi bi-check-square"></i>
                            <i v-else class="bi bi-dash-square"></i>
                            大手企業
                        </div>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-9">
                        <label class="form-label">反響対応者</label>
                        <div class="form-readonly">{{ project.contact_user?.user_name }}</div>
                    </div>
                    <div class="col-9">
                        <label class="form-label">追客担当</label>
                        <div class="form-readonly">{{ project.follow_user?.user_name }}</div>
                    </div>
                    <div class="col-9">
                        <label class="form-label">営業担当</label>
                        <div class="form-readonly">{{ project.sales_user?.user_name }}</div>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-9">
                        <label class="form-label">ステータス</label>
                        <div class="form-readonly">
                            {{ project.status_label }}
                            <span v-if="project.no_follow" class="text-danger">(追客なし)</span>
                        </div>
                    </div>
                    <div class="col-9">
                        <label class="form-label">優先度</label>
                        <div class="form-readonly">{{ project.probability?.probability_name }}</div>
                    </div>
                    <div class="col-9">
                        <label class="form-label">評価</label>
                        <div class="form-readonly">{{ project.rank_label }}</div>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-9">
                        <label class="form-label">サブ評価</label>
                        <div class="form-readonly">{{ project.detailed_rank?.detailed_rank_name }}</div>
                    </div>
                    <div class="col-9">
                        <label class="form-label">確実度</label>
                        <div class="form-readonly">{{ project.certainty?.certainty_name }}</div>
                    </div>
                    <div class="col-9">
                        <label class="form-label">計測カウント</label>
                        <div class="form-readonly">{{ project.count_type_label }}</div>
                    </div>
                    <div class="col-9">
                        <label class="form-label">認証有効期限日</label>
                        <div class="form-readonly">{{ $helper.ymd(project.certification_expiry_date) }}</div>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-9">
                        <label class="form-label">契約形態</label>
                        <div class="form-readonly">{{ project.contract_type_label }}</div>
                    </div>
                </div>
            </div>
        </transition>

        <h3 class="mb-3 mt-5">
            <i class="bi bi-caret-up-fill text-primary" :class="{'toggle-close': is_open.follow === false, 'toggle-open': is_open.follow === true,}"></i>
            <span @click="is_open.follow = !is_open.follow" role="button">追客状況</span>
        </h3>
        <transition>
            <div v-if="is_open.follow">
                <div class="row mb-3">
                    <div class="col-9">
                        <label class="form-label">次回アプローチ日</label>
                        <div class="form-readonly">{{ $helper.ymd(project.next_approaching_date) }}</div>
                    </div>
                    <div class="col-9">
                        <label class="form-label">見込日</label>
                        <div class="form-readonly">{{ $helper.ymd(project.expected_order_date) }}</div>
                    </div>
                    <div class="col-9">
                        <label class="form-label"></label>
                        <div class="form-readonly">
                            <i v-if="project.is_rescheduling === IsRescheduling.YES" class="bi bi-check-square"></i>
                            <i v-else class="bi bi-dash-square"></i>
                            リスケ
                        </div>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-36 mb-3">
                        <div class="col">
                            <label class="form-label">追客計画</label>
                            <div class="form-readonly prewrap">{{ project.follow_plan }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>

        <h3 class="mb-3 mt-5">
            <i class="bi bi-caret-up-fill text-primary" :class="{'toggle-close': is_open.contact === false, 'toggle-open': is_open.contact === true,}"></i>
            <span @click="is_open.contact = !is_open.contact" role="button">反響情報</span>
        </h3>
        <transition>
            <div v-if="is_open.contact">
                <div class="row mb-3">
                    <div class="col-9">
                        <label class="form-label">反響日</label>
                        <div class="form-readonly">{{ $helper.ymd(project.contact_date) }}</div>
                    </div>
                    <div class="col-9">
                        <label class="form-label">紹介元（コンサル以外）</label>
                        <div class="form-readonly">{{ project.referrer }}</div>
                    </div>
                    <div class="col-9">
                        <label class="form-label">チャネル</label>
                        <div class="form-readonly">{{ project.channel?.channel_name }}</div>
                    </div>
                    <div class="col-9">
                        <label class="form-label">キャンペーンNo</label>
                        <div class="form-readonly">{{ project.campaign_number }}</div>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col">
                        <label class="form-label">反響備考</label>
                        <div class="form-readonly prewrap">{{ project.contact_note }}</div>
                    </div>
                </div>
            </div>
        </transition>

        <h3 class="mb-3 mt-5">
            <i class="bi bi-caret-up-fill text-primary" :class="{'toggle-close': is_open.consultant_introduction === false, 'toggle-open': is_open.consultant_introduction === true,}"></i>
            <span @click="is_open.consultant_introduction = !is_open.consultant_introduction" role="button">コンサル紹介情報</span>
        </h3>
        <transition>
            <div v-if="is_open.consultant_introduction" class="row mb-3">
                <div class="col-9">
                    <label class="form-label">紹介元（お客様名）</label>
                    <div class="form-readonly">{{ project.referrer_company }}</div>
                </div>
                <div class="col-9">
                    <label class="form-label">担当コンサル</label>
                    <div class="form-readonly">{{ project.referrer_company_consultant_user?.user_name }}</div>
                </div>
            </div>
        </transition>

        <h3 class="mb-3 mt-5">
            <i class="bi bi-caret-up-fill text-primary" :class="{'toggle-close': is_open.customer_contact === false, 'toggle-open': is_open.customer_contact === true,}"></i>
            <span @click="is_open.customer_contact = !is_open.customer_contact" role="button">客先担当者</span>
        </h3>
        <transition>
            <div v-if="is_open.customer_contact">
                <template v-for="contact in project.contacts" :key="contact.project_contact_id">
                    <div class="row mb-3">
                        <div class="col-9">
                            <label class="form-label">氏名</label>
                            <div class="form-readonly">{{ contact.contact_name }}</div>
                        </div>
                        <div class="col-9">
                            <label class="form-label">フリガナ</label>
                            <div class="form-readonly">{{ contact.contact_name_kana }}</div>
                        </div>
                        <div class="col-9">
                            <label class="form-label">役職</label>
                            <div class="form-readonly">
                                <i class="bi bi-star" v-if="contact.is_ceo"></i>
                                {{ contact.position }}
                            </div>
                        </div>
                        <div class="col-9">
                            <label class="form-label">部署</label>
                            <div class="form-readonly">{{ contact.division }}</div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-9">
                            <label class="form-label">携帯</label>
                            <div class="form-readonly">{{ contact.phone }}</div>
                        </div>
                        <div class="col-18">
                            <label class="form-label">メール</label>
                            <div class="form-readonly">{{ contact.email }}</div>
                        </div>
                        <div class="col-9">
                            <label class="form-label">連絡先種別</label>
                            <div class="form-readonly">{{ contact.contact_type_label }}</div>
                        </div>
                    </div>
                </template>
            </div>
        </transition>

        <h3 class="mb-3 mt-5">
            <i class="bi bi-caret-up-fill text-primary" :class="{'toggle-close': is_open.address === false, 'toggle-open': is_open.address === true,}"></i>
            <span @click="is_open.address = !is_open.address" role="button">住所</span>
        </h3>
        <transition>
            <div v-if="is_open.address">
                <div class="row mb-3">
                    <div class="col-9">
                        <label class="form-label">郵便番号</label>
                        <div class="form-readonly">〒 {{ project.zip_code }}</div>
                    </div>
                    <div class="col-9">
                        <label class="form-label">都道府県</label>
                        <div class="form-readonly">{{ project.area?.area_name }}</div>
                    </div>
                    <div class="col-9">
                        <label class="form-label">市区郡</label>
                        <div class="form-readonly">{{ project.city }}</div>
                    </div>
                    <div class="col-9">
                        <label class="form-label">町名・番地</label>
                        <div class="form-readonly">{{ project.street }}</div>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-18">
                        <label class="form-label">Google Maps</label>
                        <div class="form-readonly">{{ project.google_map }}</div>
                    </div>
                    <div class="col-9">
                        <label class="form-label">最寄駅</label>
                        <div class="form-readonly">{{ project.closest_station }}</div>
                    </div>
                    <div class="col-9">
                        <label class="form-label">エリア重要度</label>
                        <div class="form-readonly">{{ project.area?.area_level_label }}</div>
                    </div>
                </div>
            </div>
        </transition>

        <h3 class="mb-3 mt-5">
            <i class="bi bi-caret-up-fill text-primary" :class="{'toggle-close': is_open.appointment === false, 'toggle-open': is_open.appointment === true,}"></i>
            <span @click="is_open.appointment = !is_open.appointment" role="button">アポイント情報</span>
        </h3>
        <transition>
            <div v-if="is_open.appointment">
                <div class="row mb-3">
                    <div class="col-9">
                        <label class="form-label">初回アポイント日時</label>
                        <div class="form-readonly">{{ $helper.ymdhi(project.first_appointment_datetime) }}</div>
                    </div>
                    <div class="col-9">
                        <label class="form-label">最新アポイント日時</label>
                        <div class="form-readonly">{{ $helper.ymdhi(project.latest_appointment_datetime) }}</div>
                    </div>
                    <div class="col-9">
                        <label class="form-label">初回アポ取得日</label>
                        <div class="form-readonly">{{ $helper.ymd(project.first_making_appointment_date) }}</div>
                    </div>
                    <div class="col-9">
                        <label class="form-label">最新アポ取得日</label>
                        <div class="form-readonly">{{ $helper.ymd(project.latest_making_appointment_date) }}</div>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col">
                        <label class="form-label">アポイント備考</label>
                        <div class="form-readonly prewrap">{{ project.appointment_note }}</div>
                    </div>
                </div>
            </div>
        </transition>

        <h3 class="mb-3 mt-5">
            <i class="bi bi-caret-up-fill text-primary" :class="{'toggle-close': is_open.business === false, 'toggle-open': is_open.business === true,}"></i>
            <span @click="is_open.business = !is_open.business" role="button">営業報告</span>
        </h3>
        <transition>
            <div v-if="is_open.business">
                <div class="row mb-3">
                    <div class="col-18">
                        <label class="form-label">競合</label>
                        <div class="form-readonly">{{ project.competitors_label }}</div>
                    </div>
                    <div class="col-18">
                        <label class="form-label">候補落ち競合</label>
                        <div class="form-readonly">{{ project.lost_competitors_label }}</div>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-18">
                        <label class="form-label">競合（その他）</label>
                        <div class="form-readonly prewrap">{{ project.competitor_note }}</div>
                    </div>
                    <div class="col-9">
                        <label class="form-label">取得理由</label>
                        <div class="form-readonly">{{ project.purpose_label }}</div>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-18">
                        <label class="form-label">規格</label>
                        <div class="form-readonly">{{ project.standards_label }}</div>
                        <div v-if="project.standard_transferred" class="mt-1 small">{{ $helper.ymd(project.standard_transferred) }} 自動変更</div>
                    </div>
                    <div class="col-18">
                        <label class="form-label">その他規格・備考</label>
                        <div class="form-readonly prewrap">{{ project.standard_note }}</div>
                    </div>
                </div>
                <div class="row mb-3 align-items-end">
                    <div v-if="project.department?.use_proposal_price" class="col-9">
                        <label class="form-label">提案金額</label>
                        <div class="form-readonly text-end">{{ $helper.number(project.proposal_price) }} 万円</div>
                    </div>
                    <div class="col-9">
                        <div class="form-readonly">
                            <i v-if="project.can_video_meeting === CanVideoMeeting.YES" class="bi bi-check-square"></i>
                            <i v-else class="bi bi-dash-square"></i>
                            ビデオ通話
                        </div>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-36">
                        <label class="form-label">注意事項</label>
                        <div class="form-readonly prewrap">{{ project.sales_note }}</div>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-36">
                        <label class="form-label">担当コンサル選定時の注意事項</label>
                        <div class="form-readonly prewrap">{{ project.consultant_note }}</div>
                    </div>
                </div>
            </div>
        </transition>

        <h3 class="mb-3 mt-5">
            <i class="bi bi-caret-up-fill text-primary" :class="{'toggle-close': is_open.meeting_memo === false, 'toggle-open': is_open.meeting_memo === true,}"></i>
            <span @click="is_open.meeting_memo = !is_open.meeting_memo" role="button">打合わせメモ</span>
        </h3>
        <transition>
            <div v-if="is_open.meeting_memo" class="row mb-3">
                <template v-for="activity in project.activities_oldest" :key="activity.activity_id">
                    <!-- 営業報告内容（打合せメモ）があるやつのみ（古い順）-->
                    <div v-if="!$helper.isBlank(activity.meeting_detail)" class="col-36 mb-5">
                        <label><i class="bi bi-clipboard text-primary"></i> {{ $helper.ymd(activity.activity_date) }}</label>
                        <p class="p-3 border border-2 prewrap">{{ activity.meeting_detail }}</p>
                    </div>
                </template>
            </div>
        </transition>

        <h3 class="mb-3 mt-5">
            <i class="bi bi-caret-up-fill text-primary" :class="{'toggle-close': is_open.web_form === false, 'toggle-open': is_open.web_form === true,}"></i>
            <span @click="is_open.web_form = !is_open.web_form" role="button">Webフォームからの情報</span>
        </h3>
        <transition>
            <div v-if="is_open.web_form">
                <div class="row mb-3">
                    <div class="col-9">
                        <label class="form-label">メールマガジン購読</label>
                        <div class="form-readonly">{{ project.subscribe_mail_magazine_label }}</div>
                    </div>
                    <div class="col-18">
                        <label class="form-label">送信元ページ</label>
                        <div class="form-readonly">{{ project.detailed_channel?.detailed_channel_name }}</div>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-36 mb-3">
                        <label class="form-label">資料請求</label>
                        <div class="form-readonly prewrap">{{ project.form_document }}</div>
                    </div>
                    <div class="col-36 mb-3">
                        <label class="form-label">ご検討事項</label>
                        <div class="form-readonly prewrap">{{ project.form_consideration }}</div>
                    </div>
                    <div class="col-36 mb-3">
                        <label class="form-label">ご希望のサービス内容</label>
                        <div class="form-readonly prewrap">{{ project.form_request }}</div>
                    </div>
                    <div class="col-36">
                        <label class="form-label">お問合せ内容／ご相談内容／ご相談・ご要望・希望日</label>
                        <div class="form-readonly prewrap">{{ project.form_inquiry }}</div>
                    </div>
                </div>
            </div>
        </transition>

        <h3 class="mb-3 mt-5">
            <i class="bi bi-caret-up-fill text-primary" :class="{'toggle-close': is_open.order_information === false, 'toggle-open': is_open.order_information === true,}"></i>
            <span @click="is_open.order_information = !is_open.order_information" role="button">受注情報</span>
        </h3>
        <transition>
            <div v-if="is_open.order_information">
                <div class="row mb-3 align-items-start">
                    <div class="col-9">
                        <label class="form-label">受注日</label>
                        <div class="form-readonly">{{ $helper.ymd(project.ordered_date) }}</div>
                    </div>
                    <div class="col-9">
                        <label class="form-label">受注理由</label>
                        <div class="form-readonly">{{ project.ordered_reason_label }}</div>
                    </div>
                </div>
                <div class="row mb-3 align-items-start">
                    <div class="col-36">
                        <label class="form-label">受注理由詳細</label>
                        <div class="form-readonly prewrap">{{ project.ordered_reason_detail }}</div>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-9">
                        <label class="form-label">顧客番号</label>
                        <div class="form-readonly">{{ project.client_number }}</div>
                    </div>
                    <div class="col-9">
                        <label class="form-label">契約金額</label>
                        <div class="form-readonly text-end">{{ $helper.number(project.contract_price) }} 円</div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-9">
                        <label class="form-label">契約ステータス</label>
                        <div class="form-readonly">{{ project.contract_status_label }}</div>
                    </div>
                    <div class="col-9">
                        <label class="form-label">契約書原案送付日</label>
                        <div class="form-readonly">{{ $helper.ymd(project.contract_request_review_date) }}</div>
                    </div>
                    <div class="col-9">
                        <label class="form-label">クラウドサイン送付日</label>
                        <div class="form-readonly">{{ $helper.ymd(project.contract_request_sign_date) }}</div>
                    </div>
                    <div class="col-9">
                        <label class="form-label">契約締結完了日</label>
                        <div class="form-readonly">{{ $helper.ymd(project.contract_execute_date) }}</div>
                    </div>
                </div>
            </div>
        </transition>

        <h3 class="mb-3 mt-5">
            <i class="bi bi-caret-up-fill text-primary" :class="{'toggle-close': is_open.lost_cause === false, 'toggle-open': is_open.lost_cause === true,}"></i>
            <span @click="is_open.lost_cause = !is_open.lost_cause" role="button">失注理由</span>
        </h3>
        <transition>
            <div v-if="is_open.lost_cause">
                <div class="row mb-3">
                    <div class="col-9">
                        <label class="form-label">失注日</label>
                        <div class="form-readonly">{{ $helper.ymd(project.lost_date) }}</div>
                    </div>
                    <div class="col-9">
                        <label class="form-label">失注先</label>
                        <div class="form-readonly">{{ project.lost_against_competitor?.competitor_name }}</div>
                    </div>
                    <div class="col-9">
                        <label class="form-label">他社落ち理由</label>
                        <div class="form-readonly">{{ project.lost_reason_label }}</div>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-36">
                        <label class="form-label">失注理由</label>
                        <div class="form-readonly prewrap">{{ project.lost_cause }}</div>
                    </div>
                </div>
            </div>
        </transition>

        <h3 class="mb-3 mt-5">
            <i class="bi bi-caret-up-fill text-primary" :class="{'toggle-close': is_open.past_history === false, 'toggle-open': is_open.past_history === true,}"></i>
            <span @click="is_open.past_history = !is_open.past_history" role="button">過去の訪問・追跡履歴</span>
        </h3>
        <transition>
            <div v-if="is_open.past_history" class="row mb-3">
                <div class="col-36">
                    <label class="form-label">過去の訪問・追跡履歴</label>
                    <div class="form-readonly prewrap">{{ project.history }}</div>
                </div>
            </div>
        </transition>
    </section>

</template>

<script>
import axios from 'axios';
import Project from '@/models/entities/project';
import Area from '@/models/entities/area';
import IsRescheduling from '@/models/enums/is-rescheduling';
import IsLostCompetitor from '@/models/enums/is-lost-competitor';
import Status from '@/models/enums/status';
import Standard from '@/models/enums/standard';
import Rank from '@/models/enums/rank';
import CountType from '@/models/enums/count-type';
import ContactType from '@/models/enums/contact-type';
import ContractStatus from '@/models/enums/contract-status';
import Purpose from '@/models/enums/purpose';
import AreaLevel from '@/models/enums/area-level';
import CanVideoMeeting from '@/models/enums/can-video-meeting';
import IsMajor from '@/models/enums/is-major';
import LostReason from '@/models/enums/lost-reason';
import OrderedReason from '@/models/enums/ordered-reason';
import SubscribeMailMagazine from '@/models/enums/subscribe-mail-magazine';
import ContractType from '@/models/enums/contract-type';
import ProjectContact from '@/models/entities/project-contact';
import FormSelect from '@/components/forms/FormSelect';
import FormSelectSearch from '@/components/forms/FormSelectSearch';
import FormSelectObjectSearchMultiple from '@/components/forms/FormSelectObjectSearchMultiple';
import FormSelectSearchMultiple from '@/components/forms/FormSelectSearchMultiple';
import FormSelectObject from '@/components/forms/FormSelectObject';
import FormSelectObjectSearch from '@/components/forms/FormSelectObjectSearch';
import FormInputDate from '@/components/forms/FormInputDate';
import FormInputNumber from '@/components/forms/FormInputNumber';
import FormCheckbox from '@/components/forms/FormCheckbox';
import FormInput from '@/components/forms/FormInput';
import FormTextarea from '@/components/forms/FormTextarea';
import Probability from '@/models/entities/probability';
import DetailedRank from '@/models/entities/detailed-rank';
import Certainty from '@/models/entities/certainty';
import Competitor from '@/models/entities/competitor';

export default {
    name: 'ProjectDetail',
    components: {
        FormSelect,
        FormSelectObject,
        FormSelectObjectSearch,
        FormInputDate,
        FormInputNumber,
        FormInput,
        FormCheckbox,
        FormTextarea,
        FormSelectSearch,
        FormSelectObjectSearchMultiple,
        FormSelectSearchMultiple,
    },
    inject: [
        'startScreenLoading',
        'endScreenLoading',
        'showErrorMessage',
        'showMessage',
    ],
    props: {
        project: {
            type: Object,
            default: new Project(),
        },
        options_department: {
            type: Object,
        },
        options_user: {
            type: Object,
        },
        options_user_object: {
            type: Object,
        },
        options_channel: {
            type: Object,
        },
        options_area: {
            type: Object,
        },
        options_detailed_channel: {
            type: Object,
        },
        options_probability: {
            type: Object,
        },
        options_detailed_rank: {
            type: Object,
        },
        options_certainty: {
            type: Object,
        },
        options_competitor: {
            type: Object,
        },
        // ヘッダー編集中フラグ
        is_editing_header: {
            type: Boolean,
        }
    },
    emits: [
        'changeDepartment',
        'update',
        'updateOptionsProbability',
        'updateOptionsDetailedRank',
        'updateOptionsCertainty',
        'updateOptionsCompetitor',
        'changeIsEditDetail',
    ],
    data() {
        return {
            // 編集・閲覧切り替え
            is_edit: false,
            is_disabled: false,

            IsRescheduling,
            CanVideoMeeting,
            IsMajor,
            Status,
            Standard,
            Rank,
            CountType,
            Purpose,
            AreaLevel,
            SubscribeMailMagazine,
            ContactType,
            LostReason,
            OrderedReason,
            IsLostCompetitor,
            ContractType,
            ContractStatus,

            // 編集用
            edit_project: new Project(JSON.parse(JSON.stringify(this.project))), // 値渡し

            // 事業部変更時 切り替えoption
            probabilities: this.options_probability.slice(), // 値渡し
            detailed_ranks: this.options_detailed_rank.slice(),
            certainties: this.options_certainty.slice(),
            competitors: this.options_competitor.slice(),

            // アコーディオン開閉フラグ
            is_open: {
                basic: true,
                follow: true,
                contact: true,
                consultant_introduction: false,
                customer_contact: false,
                address: true,
                appointment: false,
                business: true,
                meeting_memo: true,
                web_form: false,
                order_information: false,
                lost_cause: false,
                past_history: false,
            },

            address_to_parse: null,
        }
    },
    watch: {
        // 親コンポーネントprojectの変更を検知
        project: {
            handler(new_project) {
                this.edit_project = new Project(new_project);
            },
            deep: true
        },
        options_probability: {
            handler(new_options_probability) {
                this.probabilities = new_options_probability.slice();
            },
            deep: true,
        },
        options_detailed_rank: {
            handler(new_options_detailed_rank) {
                this.detailed_ranks = new_options_detailed_rank.slice();
            },
            deep: true,
        },
        options_certainty: {
            handler(new_options_certainty) {
                this.certainties = new_options_certainty.slice();
            },
            deep: true,
        },
        options_competitor: {
            handler(new_options_competitor) {
                this.competitors = new_options_competitor.slice();
            },
            deep: true,
        },
    },
    methods: {
        // 事業部が変更されたら取得し直し
        async changeDepartment() {
            this.is_disabled = true;

            // 下記プロパティをリセット
            this.edit_project.probability = null; //優先度
            this.edit_project.detailed_rank = null; //サブ評価
            this.edit_project.certainty = null; //確実度
            this.edit_project.competitors_entities = []; //競合セット
            this.edit_project.lost_competitors_entities = []; //候補落ち競合セット

            // 選択肢を切り替え
            await Promise.all([
                // 優先度
                this.fetchProbabilities(),
                // サブ評価
                this.fetchDetailedRanks(),
                // 確実度
                this.fetchCertainties(),
                // 競合
                this.fetchCompetitors(),
            ]);

            this.is_disabled = false;
        },
        // 客先担当者追加
        addContact() {
            this.edit_project.contacts.push(new ProjectContact());
        },
        // 客先担当者削除
        removeContact(project_contact_id) {
            const remove_index = this.edit_project.contacts.findIndex(contact => contact.project_contact_id === project_contact_id);
            this.edit_project.contacts.splice(remove_index, 1);
        },
        // 保存処理
        save() {
            const is_primary_contacts = this.edit_project.contacts.filter(contact => contact.contact_type === this.ContactType.PRIMARY);
            // 主担当者がいない場合エラー
            if (is_primary_contacts.length === 0) {
                this.showErrorMessage('客先担当者の主担当は必須です')
                return;
            }

            // 主担当者が複数人存在したらエラー
            if (is_primary_contacts.length > 1) {
                this.showErrorMessage('客先担当者の主担当は複数人登録できません')
                return;
            }

            this.startScreenLoading();

            this.$http.put(`/project/${this.edit_project.project_id}`, this.edit_project)
            .then((response) => {
                this.is_edit = false;
                this.changeIsEdit(false);

                this.showMessage('案件情報を保存しました');
                // 事業部が変更されていたら optionの変更emit
                if (this.project.department.department_id !== this.edit_project.department.department_id) {
                    this.$emit('updateOptionsProbability', this.probabilities.slice());
                    this.$emit('updateOptionsDetailedRank', this.detailed_ranks.slice());
                    this.$emit('updateOptionsCertainty', this.certainties.slice());
                    this.$emit('updateOptionsCompetitor', this.competitors.slice());
                }

                this.$emit('update', response.data);
            })
            .finally(() => {
                this.endScreenLoading();
            })
        },
        // 優先度マスタ取得
        fetchProbabilities() {
            return new Promise(resolve => {
                this.probabilities.splice(0);

                this.$http.get('/master/probability', {
                    params: {
                        department: this.edit_project.department.department_id,
                    }
                })
                .then(response => {
                    for (let row of response.data) {
                        let probability = new Probability(row);
                        this.probabilities.push(probability);
                    }
                })
                .finally(() => {
                    resolve(true);
                })
            })
        },
        // サブ評価マスタ取得
        fetchDetailedRanks() {
            return new Promise(resolve => {
                this.detailed_ranks.splice(0);

                this.$http.get('/master/detailed-rank', {
                    params: {
                        department: this.edit_project.department.department_id,
                    }
                })
                .then(response => {
                    for (let row of response.data) {
                        let detailed_rank = new DetailedRank(row);
                        this.detailed_ranks.push(detailed_rank);
                    }
                })
                .finally(() => {
                    resolve(true);
                })
            })
        },
        // 確実度マスタ取得
        fetchCertainties() {
            return new Promise(resolve => {
                this.certainties.splice(0);

                this.$http.get('/master/certainty', {
                    params: {
                        department: this.edit_project.department.department_id,
                    }
                })
                .then(response => {
                    for (let row of response.data) {
                        let certainty = new Certainty(row);
                        this.certainties.push(certainty);
                    }
                })
                .finally(() => {
                    resolve(true);
                })
            })
        },
        // 競合マスタ取得
        fetchCompetitors() {
            return new Promise(resolve => {
                this.competitors.splice(0);

                this.$http.get('/master/competitor', {
                    params: {
                        department: this.edit_project.department.department_id,
                    }
                })
                .then(response => {
                    for (let row of response.data) {
                        let competitor = new Competitor(row);
                        this.competitors.push(competitor);
                    }
                })
                .finally(() => {
                    resolve(true);
                })
            })
        },
        // 親に渡して案件情報と顧客情報の編集を制御
        changeIsEdit(bool) {
            this.$emit('changeIsEditDetail', bool);
        },
        // 郵便番号で住所を検索して上書き
        searchAddressByZip() {
            if (!this.edit_project.zip_code) {
                return;
            }

            // $http がつくと不要なヘッダがついて cors で弾かれるので、生 axios 使用
            axios.get('https://zipcloud.ibsnet.co.jp/api/search?zipcode=' + this.edit_project.zip_code)
            .then(response => {
                if (response.data.results && response.data.results.length > 0) {
                    let record = response.data.results[0];
                    this.edit_project.area = new Area({
                        area_id: record.prefcode,
                        area_name: record.address1,
                    });
                    this.edit_project.city = record.address2;
                    this.edit_project.street = record.address3;
                }
            });
        },
        // 住所のパース
        parseAddress() {
            this.$http.get('/address/parse', {
                params: {
                    keyword: this.address_to_parse,
                }
            })
            .then(response => {
                this.edit_project.zip_code = response.data.zip_code;
                this.edit_project.area = new Area(response.data.area);
                this.edit_project.city = response.data.city;
                this.edit_project.street = response.data.street;
            });
        }
    },
}
</script>

<style scoped>
    .row { align-items: stretch; }
    .section div.row>* {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .form-check-label:hover {cursor: pointer;}
</style>
