<template>

    <!-- 活動登録 -->
    <section class="section">
        <h3 class="section-title">活動登録</h3>
        <ul class="nav nav-tabs mb-3" id="myTab" role="tablist">
            <li class="nav-item" role="presentation">
                <button
                class="nav-link px-3"
                :class="{'active': is_active === ActivityType.APPROACH}"
                type="button"
                @click="changeActivityType(ActivityType.APPROACH)"
                >
                アプローチ記録
                </button>
            </li>
            <li class="nav-item" role="presentation">
                <button
                class="nav-link px-3"
                :class="{'active': is_active === ActivityType.APPOINTMENT}"
                type="button"
                @click="changeActivityType(ActivityType.APPOINTMENT)"
                >
                アポ取得報告
                </button>
            </li>
            <li class="nav-item" role="presentation">
                <button
                class="nav-link px-3"
                :class="{'active': is_active === ActivityType.MEETING}"
                type="button"
                @click="changeActivityType(ActivityType.MEETING)"
                >
                営業報告
                </button>
            </li>
        </ul>

        <form ref="form">
            <div>
                <!--アプローチ記録-->
                <div v-if="is_active === ActivityType.APPROACH">
                    <div class="row mb-3">
                        <div class="col-18">
                            <label class="form-label">件名 <i class="required"></i></label>
                            <form-select
                                v-model="activity_to_create.activity_detailed_type"
                                :options="ActivityDetailedType.approachOptions()"
                                :required="true"
                            ></form-select>
                        </div>
                        <div class="col-18">
                            <label class="form-label">割り当て先 <i class="required"></i></label>
                            <form-select-search
                                v-model="activity_to_create.user.user_id"
                                :options="options_user"
                                :required="true"
                            ></form-select-search>
                        </div>
                    </div>
                    <div class="row mb-3 align-items-end">
                        <div class="col-18">
                            <label class="form-label">活動日 <i class="required"></i></label>
                            <form-input-date v-model="activity_to_create.activity_date" :required="true"></form-input-date>
                        </div>
                        <div class="col-18">
                            <form-input-time v-model="activity_to_create.activity_time" :required="true"></form-input-time>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col">
                            <label class="form-label">内容</label>
                            <form-textarea v-model="activity_to_create.approach_detail"></form-textarea>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-18">
                            <label class="form-label">ステータス</label>
                            <form-select
                                v-model="activity_to_create.status"
                                :options="Status.options()"
                            ></form-select>
                        </div>
                    </div>

                    <div class="d-flex justify-content-between align-items-center mt-5 mb-3">
                        <h4 class="m-0"><i class="bi bi-megaphone-fill text-primary"></i> お客様の声</h4>
                        <button type="button" class="btn btn-link" v-if="show_voice" @click="show_voice = false"><i class="bi bi-caret-up-square"></i></button>
                        <button type="button" class="btn btn-link" v-else @click="show_voice = true"><i class="bi bi-caret-down-square"></i></button>
                    </div>
                    <div class="row mb-3" v-if="show_voice">
                        <div class="col-18">
                            <label class="form-label">場面</label>
                            <form-select
                                v-model="activity_to_create.voice_scene"
                                :options="VoiceScene.options()"
                                empty_option="----"
                            ></form-select>
                        </div>
                    </div>
                    <div class="row mb-3" v-if="show_voice">
                        <div class="col">
                            <label class="form-label">お客様の声</label>
                            <form-textarea v-model="activity_to_create.voice_content"></form-textarea>
                        </div>
                    </div>
                    <div class="row mb-3" v-if="show_voice">
                        <div class="col">
                            <label class="form-label">経緯</label>
                            <form-textarea v-model="activity_to_create.voice_background"></form-textarea>
                        </div>
                    </div>
                    <div class="row mb-3" v-if="show_voice">
                        <div class="col">
                            <label class="form-label">対応</label>
                            <form-textarea v-model="activity_to_create.voice_action"></form-textarea>
                        </div>
                    </div>
                </div>

                <!--アポ記録-->
                <div v-if="is_active === ActivityType.APPOINTMENT">
                    <div class="row mb-3">
                        <div class="col-18">
                            <label class="form-label">件名 <i class="required"></i></label>
                            <form-select
                                v-model="activity_to_create.activity_detailed_type"
                                :options="ActivityDetailedType.appointmentOptions()"
                                :required="true"
                            ></form-select>
                        </div>
                        <div class="col-18">
                            <label class="form-label">割り当て先 <i class="required"></i></label>
                            <form-select-search
                                v-model="activity_to_create.user.user_id"
                                :options="options_user"
                                :required="true"
                            ></form-select-search>
                        </div>
                    </div>
                    <div class="row mb-3 align-items-end">
                        <div class="col-18">
                            <label class="form-label">活動日 <i class="required"></i></label>
                            <form-input-date v-model="activity_to_create.activity_date" :required="true"></form-input-date>
                        </div>
                        <div class="col-18">
                            <form-input-time v-model="activity_to_create.activity_time" :required="true"></form-input-time>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-18">
                            <label class="form-label">アポ取得リスト</label>
                            <form-select-object
                                v-model="activity_to_create.appointment_source"
                                :options="options_appointment_source"
                                option_value="appointment_source_id"
                                option_label="appointment_source_name"
                                empty_option="----"
                            ></form-select-object>
                        </div>
                        <div class="col-18">
                            <label class="form-label">営業担当</label>
                            <form-select-search
                                v-model="activity_to_create.sales_user.user_id"
                                :options="options_user"
                            ></form-select-search>
                        </div>
                    </div>
                    <div class="mb-3">
                        <label class="form-label">アポイント日時</label>
                        <form-input-datetime v-model="activity_to_create.appointment_datetime"></form-input-datetime>
                    </div>
                    <div class="row mb-3">
                        <div class="col-12 offset-24">
                            <label class="form-label">打合せ時間</label>
                            <div class="input-group">
                                <form-input-number v-model="activity_to_create.meeting_minutes"></form-input-number>
                                <div class="input-group-text">分</div>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col">
                            <label class="form-label">備考</label>
                            <form-textarea v-model="activity_to_create.appointment_note"></form-textarea>
                        </div>
                    </div>

                    <h4 class="mt-5 mb-4"><i class="bi bi-briefcase-fill text-primary"></i> 営業への引継事項</h4>
                    <div class="mb-3">
                        <label class="form-label">会社名 <i class="required"></i></label>
                        <form-input v-model="activity_to_create.company_name" :required="true"></form-input>
                    </div>
                    <div class="row mb-3">
                        <div class="col-18">
                            <label class="form-label">担当者名 <i class="required"></i></label>
                            <form-input v-model="activity_to_create.contact_name" :required="true"></form-input>
                        </div>
                        <div class="col-18">
                            <label class="form-label">フリガナ</label>
                            <form-input v-model="activity_to_create.contact_name_kana"></form-input>
                        </div>
                    </div>
                    <div class="mb-3">
                        <label class="form-label">電話番号</label>
                        <form-input v-model="activity_to_create.mobile_phone"></form-input>
                    </div>
                    <div class="mb-3">
                        <label class="form-label">メールアドレス</label>
                        <form-input v-model="activity_to_create.email"></form-input>
                    </div>
                    <div class="row mb-3">
                        <div class="col-18">
                            <label class="form-label">郵便番号</label>
                            <div class="input-group">
                                <span class="input-group-text">〒</span>
                                <form-input v-model="activity_to_create.zip_code" @change="searchAddressByZip()"></form-input>
                            </div>
                        </div>
                        <div class="col-18">
                            <label class="form-label">都道府県</label>
                            <form-select-object-search
                                v-model="activity_to_create.area"
                                :options="options_area"
                                option_value="area_id"
                                option_label="area_name"
                            ></form-select-object-search>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-18">
                            <label class="form-label">市区郡</label>
                            <form-input v-model="activity_to_create.city"></form-input>
                        </div>
                        <div class="col-18">
                            <label class="form-label">町名・番地</label>
                            <form-input v-model="activity_to_create.street"></form-input>
                        </div>
                    </div>
                    <div class="mb-3">
                        <label class="form-label">最寄駅</label>
                        <form-input v-model="activity_to_create.closest_station"></form-input>
                    </div>
                    <div class="row mb-3">
                        <div class="col-18">
                            <label class="form-label">打合せ方法</label>
                            <form-select
                                v-model="activity_to_create.meeting_by"
                                :options="MeetingBy.options()"
                                empty_option="----"
                            ></form-select>
                        </div>
                        <div class="col-18">
                            <label class="form-label">参加人数</label>
                            <form-input-number v-model="activity_to_create.attendance"></form-input-number>
                        </div>
                    </div>
                    <template v-if="MeetingBy.isWebMeeting(activity_to_create.meeting_by)">
                        <div class="mb-3">
                            <label class="form-label">Web会議URL</label>
                            <form-input v-model="activity_to_create.web_meeting_url"></form-input>
                        </div>
                        <div class="row mb-3">
                            <div class="col-18">
                                <label class="form-label">Web会議アカウント</label>
                                <form-input v-model="activity_to_create.web_meeting_account"></form-input>
                            </div>
                            <div class="col-18">
                                <label class="form-label">Web会議参加場所</label>
                                <form-input v-model="activity_to_create.web_meeting_place"></form-input>
                            </div>
                        </div>
                    </template>
                    <template v-if="activity_to_create.meeting_by === MeetingBy.COMING">
                        <div class="mb-3">
                            <label class="form-label">会議室</label>
                            <form-input v-model="activity_to_create.visitor_meeting_room"></form-input>
                        </div>
                    </template>
                    <div class="mb-3">
                        <label class="form-label">営業資料</label>
                        <form-select-search-multiple
                            v-model="activity_to_create.sales_documents_values"
                            :options="options_document"
                        ></form-select-search-multiple>
                    </div>
                    <div class="mb-3">
                        <label class="form-label">プレゼント資料</label>
                        <form-select-search-multiple
                            v-model="activity_to_create.present_documents_values"
                            :options="options_document"
                        ></form-select-search-multiple>
                    </div>

                    <h4 class="mt-5"><i class="bi bi-clipboard-check text-primary"></i> 営業前反社チェック</h4>
                    <div class="mb-3">
                        <label class="form-label">Webサイト</label>
                        <form-input v-model="activity_to_create.website"></form-input>
                    </div>
                    <div class="mb-3">
                        <label class="form-label">代表者名</label>
                        <form-input v-model="activity_to_create.representative_name"></form-input>
                    </div>
                    <div class="mb-3">
                        <label class="form-label">社長名で検索</label>
                        <div class="input-group">
                            <form-select
                                v-model="activity_to_create.check_representative_name"
                                :options="IsProblem.options()"
                                empty_option="----"
                            ></form-select>
                            <form-input v-model="activity_to_create.check_representative_name_note"></form-input>
                        </div>
                    </div>
                    <div class="mb-3">
                        <label class="form-label">「社名+KW」で検索</label>
                        <div class="input-group">
                            <form-select
                                v-model="activity_to_create.check_keyword_company_name"
                                :options="IsProblem.options()"
                                empty_option="----"
                            ></form-select>
                            <form-input v-model="activity_to_create.check_keyword_company_name_note"></form-input>
                        </div>
                    </div>
                    <div class="mb-3">
                        <label class="form-label">「代表者名+KW」で検索</label>
                        <div class="input-group">
                            <form-select
                                v-model="activity_to_create.check_keyword_representative_name"
                                :options="IsProblem.options()"
                                empty_option="----"
                            ></form-select>
                            <form-input v-model="activity_to_create.check_keyword_representative_name_note"></form-input>
                        </div>
                    </div>
                    <div class="mb-3">
                        <label class="form-label">「本社住所+KW」で検索</label>
                        <div class="input-group">
                            <form-select
                                v-model="activity_to_create.check_keyword_address"
                                :options="IsProblem.options()"
                                empty_option="----"
                            ></form-select>
                            <form-input v-model="activity_to_create.check_keyword_address_note"></form-input>
                        </div>
                    </div>
                    <div class="mb-3 mt-5">
                        <form-checkbox
                            v-model="activity_to_create.is_tradable"
                            option_label="取引可"
                        ></form-checkbox>
                    </div>

                    <div class="row mb-3">
                        <div class="col">
                            <form-checkbox
                                v-model="activity_to_create.is_notified_chatwork"
                                option_label="Chatwork に通知"
                            ></form-checkbox>
                        </div>
                    </div>

                    <h4 class="mt-5 mb-3"><i class="bi bi-megaphone-fill text-primary"></i> お客様の声</h4>
                    <div class="row mb-3">
                        <div class="col-18">
                            <label class="form-label">場面</label>
                            <form-select
                                v-model="activity_to_create.voice_scene"
                                :options="VoiceScene.options()"
                                empty_option="----"
                            ></form-select>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col">
                            <label class="form-label">お客様の声</label>
                            <form-textarea v-model="activity_to_create.voice_content"></form-textarea>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col">
                            <label class="form-label">経緯</label>
                            <form-textarea v-model="activity_to_create.voice_background"></form-textarea>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col">
                            <label class="form-label">対応</label>
                            <form-textarea v-model="activity_to_create.voice_action"></form-textarea>
                        </div>
                    </div>
                </div>

                <!-- 営業報告 -->
                <div v-if="is_active === ActivityType.MEETING">
                    <div class="row mb-3">
                        <div class="col-18">
                            <label class="form-label">件名 <i class="required"></i></label>
                            <form-select
                                v-model="activity_to_create.activity_detailed_type"
                                :options="ActivityDetailedType.meetingOptions()"
                                :required="true"
                            ></form-select>
                        </div>
                        <div class="col-18">
                            <label class="form-label">割り当て先 <i class="required"></i></label>
                            <form-select-search
                                v-model="activity_to_create.user.user_id"
                                :options="options_user"
                                :required="true"
                            ></form-select-search>
                        </div>
                    </div>
                    <div class="row mb-3 align-items-end">
                        <div class="col-18">
                            <label class="form-label">活動日 <i class="required"></i></label>
                            <form-input-date v-model="activity_to_create.activity_date" :required="true"></form-input-date>
                        </div>
                        <div class="col-18">
                            <form-input-time v-model="activity_to_create.activity_time" :required="true"></form-input-time>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-18">
                            <label class="form-label">会社規模</label>
                            <form-select
                                v-model="activity_to_create.company_scale"
                                :options="CompanyScale.options()"
                                empty_option="----"
                            ></form-select>
                        </div>
                        <div class="col-18">
                            <label class="form-label">従業員数</label>
                            <form-input-number v-model="activity_to_create.employee_number"></form-input-number>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-18">
                            <label class="form-label">評価</label>
                            <form-select
                                v-model="activity_to_create.rank"
                                :options="Rank.options()"
                            ></form-select>
                        </div>
                        <!--
                        <div class="col-18">
                            <label class="form-label">サブ評価</label>
                            <form-select-object
                                v-model="activity_to_create.detailed_rank"
                                :options="options_detailed_rank"
                                empty_option="----"
                                option_value="detailed_rank_id"
                                option_label="detailed_rank_name"
                            ></form-select-object>
                        </div>
                        -->
                        <div class="col-18">
                            <label class="form-label">見込日</label>
                            <form-input-date v-model="activity_to_create.expected_order_date"></form-input-date>
                        </div>
                        <!--
                        <div class="col-18">
                            <label class="form-label">優先度</label>
                            <form-select-object
                                v-model="activity_to_create.probability"
                                :options="options_probability"
                                empty_option="----"
                                option_value="probability_id"
                                option_label="probability_name"
                            ></form-select-object>
                        </div>
                        -->
                    </div>
                    <div class="row mb-3">
                        <div class="col-18">
                            <label class="form-label">確実度</label>
                            <form-select-object
                                v-model="activity_to_create.certainty"
                                :options="options_certainty"
                                empty_option="----"
                                option_value="certainty_id"
                                option_label="certainty_name"
                            ></form-select-object>
                        </div>
                        <!--
                        <div class="col-18">
                            <label class="form-label">計測カウント</label>
                            <form-select
                                v-model="activity_to_create.count_type"
                                :options="CountType.options()"
                            ></form-select>
                        </div>
                        -->
                    </div>
                    <div class="mb-3">
                        <label class="form-label">競合</label>
                        <form-select-object-search-multiple
                            v-model="activity_to_create.competitors_entities"
                            :options="options_competitor"
                            option_value="competitor_id"
                            option_label="competitor_name"
                        ></form-select-object-search-multiple>
                    </div>
                    <div class="mb-3">
                        <label class="form-label">競合（その他）</label>
                        <form-input v-model="activity_to_create.competitor_note"></form-input>
                    </div>
                    <div class="row mb-3">
                        <div class="col-18">
                            <label class="form-label">取得理由</label>
                            <form-select
                                v-model="activity_to_create.purpose"
                                :options="Purpose.options()"
                                empty_option="----"
                            ></form-select>
                        </div>
                        <div class="col-18">
                            <label class="form-label">決算期</label>
                            <div class="input-group">
                                <form-input v-model="activity_to_create.fiscal_month"></form-input>
                                <span class="input-group-text">月</span>
                            </div>
                        </div>
                    </div>
                    <div class="mb-5">
                        <label class="form-label">規格</label>
                        <form-select-search-multiple
                            v-model="activity_to_create.standards_values"
                            :options="Standard.options()"
                        ></form-select-search-multiple>
                    </div>
                    <div class="row align-items-end mb-3">
                        <div class="col-18">
                            <label class="form-label">提案金額</label>
                            <div class="input-group">
                                <form-input-number v-model="activity_to_create.proposal_price"></form-input-number>
                                <span class="input-group-text">万円</span>
                            </div>
                        </div>
                        <div class="col-18">
                            <form-checkbox
                                v-model="activity_to_create.can_video_meeting"
                                option_label="ビデオ通話"
                            ></form-checkbox>
                        </div>
                    </div>
                    <div class="mb-3">
                        <label class="form-label">追客計画</label>
                        <form-textarea v-model="activity_to_create.follow_plan"></form-textarea>
                    </div>
                    <div class="mb-3">
                        <label class="form-label">注意事項</label>
                        <form-textarea v-model="activity_to_create.sales_note"></form-textarea>
                    </div>
                    <!--
                    <div class="mb-3">
                        <label class="form-label">担当コンサル選定時の注意事項</label>
                        <form-textarea v-model="activity_to_create.consultant_note"></form-textarea>
                    </div>
                    -->
                    <div class="mb-3">
                        <label class="form-label">内容</label>
                        <form-textarea v-model="activity_to_create.meeting_detail"></form-textarea>
                    </div>

                    <h4 class="mt-5 mb-3"><i class="bi bi-megaphone-fill text-primary"></i> お客様の声</h4>
                    <div class="row mb-3">
                        <div class="col-18">
                            <label class="form-label">場面</label>
                            <form-select
                                v-model="activity_to_create.voice_scene"
                                :options="VoiceScene.options()"
                                empty_option="----"
                            ></form-select>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col">
                            <label class="form-label">お客様の声</label>
                            <form-textarea v-model="activity_to_create.voice_content"></form-textarea>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col">
                            <label class="form-label">経緯</label>
                            <form-textarea v-model="activity_to_create.voice_background"></form-textarea>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col">
                            <label class="form-label">対応</label>
                            <form-textarea v-model="activity_to_create.voice_action"></form-textarea>
                        </div>
                    </div>
                </div>
            </div>
            <div class="text-end mt-3">
                <button type="button" @click="save()" class="btn btn-lg btn-info">登録</button>
            </div>
        </form>
    </section>

</template>

<script>
import axios from 'axios';
import Project from '@/models/entities/project';
import Status from '@/models/enums/status';
import Standard from '@/models/enums/standard';
import ActivityDetailedType from '@/models/enums/activity-detailed-type';
import ActivityType from '@/models/enums/activity-type';
import MeetingBy from '@/models/enums/meeting-by';
import IsProblem from '@/models/enums/is-problem';
import CompanyScale from '@/models/enums/company-scale';
import CountType from '@/models/enums/count-type';
import ContactType from '@/models/enums/contact-type';
import Rank from '@/models/enums/rank';
import Purpose from '@/models/enums/purpose';
import VoiceScene from '@/models/enums/voice-scene';
import FormSelect from '@/components/forms/FormSelect';
import FormInputDate from '@/components/forms/FormInputDate';
import FormInputDatetime from '@/components/forms/FormInputDatetime';
import FormInputTime from '@/components/forms/FormInputTime';
import FormInput from '@/components/forms/FormInput';
import FormTextarea from '@/components/forms/FormTextarea';
import FormSelectObject from '@/components/forms/FormSelectObject';
import FormSelectObjectSearch from '@/components/forms/FormSelectObjectSearch';
import FormSelectSearch from '@/components/forms/FormSelectSearch';
import FormSelectSearchMultiple from '@/components/forms/FormSelectSearchMultiple';
import FormSelectObjectSearchMultiple from '@/components/forms/FormSelectObjectSearchMultiple';
import FormInputNumber from '@/components/forms/FormInputNumber';
import FormCheckbox from '@/components/forms/FormCheckbox';
import Area from '@/models/entities/area';
import Activity from '@/models/entities/activity';
import User from '@/models/entities/user';
import { dateYmdForInputDate, dateFormat } from '@/utilities/date-format';

export default {
    name: 'ProjectActivityAdd',
    components: {
        FormSelect,
        FormInputDate,
        FormInputDatetime,
        FormInputTime,
        FormSelectObject,
        FormSelectObjectSearch,
        FormSelectSearch,
        FormSelectSearchMultiple,
        FormSelectObjectSearchMultiple,
        FormInput,
        FormTextarea,
        FormInputNumber,
        FormCheckbox,
    },
    inject: [
        'startScreenLoading',
        'endScreenLoading',
        'showErrorMessage',
        'showMessage',
    ],
    props: {
        project: {
            type: Object,
            default: new Project(),
        },
        options_user: {
            type: Object,
        },
        options_area: {
            type: Object,
        },
        options_detailed_rank: {
            type: Object,
        },
        options_document: {
            type: Object,
        },
        options_probability: {
            type: Object,
        },
        options_certainty: {
            type: Object,
        },
        options_competitor: {
            type: Object,
        },
        options_appointment_source: {
            type: Object,
        },
    },
    emits: [
        'save',
    ],
    data() {
        return {
            // option
            Status,
            Standard,
            ActivityDetailedType,
            ActivityType,
            MeetingBy,
            IsProblem,
            CompanyScale,
            Rank,
            CountType,
            Purpose,
            ContactType,
            VoiceScene,

            // タブ切り替えフラグ
            is_active: null,
            show_voice: false,

            activity_to_create: new Activity(),

            dateYmdForInputDate,
            dateFormat,
        }
    },
    watch: {
        project: {
            handler() {
                // アプローチ記録
                if (this.is_active === ActivityType.APPROACH) {
                    this.setDefaultApproach();
                }
                // アポ取得報告
                if (this.is_active === ActivityType.APPOINTMENT) {
                    this.setDefaultAppointment();
                }
                // 営業報告
                if (this.is_active === ActivityType.MEETING) {
                    this.setDefaultMeeting();
                }
            },
            deep: true,
        }
    },
    created() {
        this.is_active = ActivityType.APPROACH;
        this.setDefaultApproach();
    },
    methods: {
        // 活動種別切り替え
        changeActivityType(activity_type) {
            // リセット
            this.activity_to_create = new Activity();
            // アプローチ記録
            if (activity_type === this.ActivityType.APPROACH) {
                this.setDefaultApproach();
                this.is_active = this.ActivityType.APPROACH;
            }

            // アポ取得報告
            if (activity_type === this.ActivityType.APPOINTMENT) {
                this.setDefaultAppointment();
                this.is_active = this.ActivityType.APPOINTMENT;
            }

            // 営業報告
            if (activity_type === this.ActivityType.MEETING) {
                this.setDefaultMeeting();
                this.is_active = this.ActivityType.MEETING;
            }
        },
        // 活動種別「アプローチ記録」初期値セット
        setDefaultApproach() {
            // 活動種別
            this.activity_to_create.activity_type = ActivityType.APPROACH;
            // 件名
            this.activity_to_create.activity_detailed_type = ActivityDetailedType.FOLLOW;
            // 割り当て先
            this.activity_to_create.user.user_id = this.$store.state.auth.user_id;
            // 活動日
            this.activity_to_create.activity_date = this.dateYmdForInputDate(new Date());
            this.activity_to_create.activity_time = this.dateFormat('H:i:s', new Date());
            // ステータス
            this.activity_to_create.status = this.project.status;
            // アポイント日時
            this.activity_to_create.appointment_datetime = null;
            // アプローチ内容
            this.activity_to_create.approach_detail = '■現状\n\n■課題\n\n■次回アクション';
        },
        // 活動種別「アポ取得報告」初期値セット
        setDefaultAppointment() {
            // 活動種別
            this.activity_to_create.activity_type = ActivityType.APPOINTMENT;
            // 件名
            this.activity_to_create.activity_detailed_type = ActivityDetailedType.APPOINTMENT;
            // 活動日
            this.activity_to_create.activity_date = this.dateYmdForInputDate(new Date());
            this.activity_to_create.activity_time = this.dateFormat('H:i:s', new Date());
            // 割り当て先
            this.activity_to_create.user.user_id = this.$store.state.auth.user_id;
            // 営業担当
            this.activity_to_create.sales_user = new User(this.project.sales_user);
            // 打合せ時間
            this.activity_to_create.meeting_minutes = 60;
            // 内容
            this.activity_to_create.meeting_detail = '■現状 ■課題 ■次回アクション';
            // ステータス
            this.activity_to_create.status = this.project.status;
            // アポイント備考
            this.activity_to_create.appointment_note = this.project.appointment_note;
            // 会社名
            this.activity_to_create.company_name = this.project.customer.company_name;
            // 郵便番号
            this.activity_to_create.zip_code = this.project.zip_code;
            // 都道府県
            this.activity_to_create.area = this.project.area;
            // 市区郡
            this.activity_to_create.city = this.project.city;
            // 町名・番地
            this.activity_to_create.street = this.project.street;
            // 最寄駅
            this.activity_to_create.closest_station = this.project.closest_station;
            // Webサイト
            this.activity_to_create.website = this.project.customer.website;
            // 代表者名
            this.activity_to_create.representative_name = this.project.customer.representative_name;
            // 社長名検索
            this.activity_to_create.check_representative_name = this.project.customer.check_representative_name;
            this.activity_to_create.check_representative_name_note = this.project.customer.check_representative_name_note;
            // 社名+kw検索
            this.activity_to_create.check_keyword_company_name = this.project.customer.check_keyword_company_name;
            this.activity_to_create.check_keyword_company_name_note = this.project.customer.check_keyword_company_name_note;
            // 代表者名+kw検索
            this.activity_to_create.check_keyword_representative_name = this.project.customer.check_keyword_representative_name;
            this.activity_to_create.check_keyword_representative_name_note = this.project.customer.check_keyword_representative_name_note;
            // 本社住所+kw検索
            this.activity_to_create.check_keyword_address = this.project.customer.check_keyword_address;
            this.activity_to_create.check_keyword_address_note = this.project.customer.check_keyword_address_note;
            // 担当者名
            this.activity_to_create.contact_name = this.primaryUserName();
            // フリガナ
            this.activity_to_create.contact_name_kana = this.primaryUserNameKana();
            // 電話番号
            this.activity_to_create.mobile_phone = this.primaryUserPhone();
            // メールアドレス
            this.activity_to_create.email = this.primaryUserEmail();
            // アポイント日時
            this.activity_to_create.appointment_datetime = null;
            // 取引可
            this.activity_to_create.is_tradable = this.project.customer.is_tradable;
        },
        // 活動種別「営業報告」初期値セット
        setDefaultMeeting() {
            // 活動種別
            this.activity_to_create.activity_type = ActivityType.MEETING;
            // 件名
            this.activity_to_create.activity_detailed_type = ActivityDetailedType.NEW_VISIT;
            // 割り当て先
            this.activity_to_create.user.user_id = this.$store.state.auth.user_id;
            // 活動日
            this.activity_to_create.activity_date = this.dateYmdForInputDate(new Date());
            this.activity_to_create.activity_time = this.dateFormat('H:i:s', new Date());
            // 会社規模
            this.activity_to_create.company_scale = this.project.customer.company_scale;
            // 従業員数
            this.activity_to_create.employee_number = this.project.customer.employee_number;
            // 評価
            this.activity_to_create.rank = this.project.rank;
            // サブ評価
            //this.activity_to_create.detailed_rank = this.project.detailed_rank;
            // 見込日
            this.activity_to_create.expected_order_date = this.project.expected_order_date;
            // 優先度
            //this.activity_to_create.probability = this.project.probability;
            // 確実度
            this.activity_to_create.certainty = this.project.certainty;
            // 計測カウント
            //this.activity_to_create.count_type = this.project.count_type;
            // 競合
            this.activity_to_create.competitors = this.project.competitors;
            // 競合（その他）
            this.activity_to_create.competitor_note = this.project.competitor_note;
            // 取得理由
            this.activity_to_create.purpose = this.project.purpose;
            // 決算期
            this.activity_to_create.fiscal_month = this.project.customer.fiscal_month;
            // ビデオ通話
            this.activity_to_create.can_video_meeting = this.project.can_video_meeting;
            // 提案金額
            this.activity_to_create.proposal_price = this.project.proposal_price;
            // 追客計画
            this.activity_to_create.follow_plan = this.project.follow_plan;
            // 注意事項
            this.activity_to_create.sales_note = this.project.sales_note;
            // 担当コンサル選定時の注意事項
            //this.activity_to_create.consultant_note = this.project.consultant_note;
            // アポイント日時
            this.activity_to_create.appointment_datetime = null;
        },
        // 主担当者名
        primaryUserName() {
            if (this.project.contacts.length) {
                const major_contact = this.project.contacts.find(contact => contact.contact_type === ContactType.PRIMARY);
                if (!this.$helper.isBlank(major_contact)) {
                    return major_contact.contact_name;
                }
            }

            return '';
        },
        // 主担当者名かな
        primaryUserNameKana() {
            if (this.project.contacts.length) {
                const major_contact = this.project.contacts.find(contact => contact.contact_type === ContactType.PRIMARY);
                if (!this.$helper.isBlank(major_contact)) {
                    return major_contact.contact_name_kana;
                }
            }

            return '';
        },
        // 主担当者電話番号
        primaryUserPhone() {
            if (this.project.contacts.length) {
                const major_contact = this.project.contacts.find(contact => contact.contact_type === ContactType.PRIMARY);
                if (!this.$helper.isBlank(major_contact)) {
                    return major_contact.phone;
                }
            }

            return '';
        },
        // 主担当者メールアドレス
        primaryUserEmail() {
            if (this.project.contacts.length) {
                const major_contact = this.project.contacts.find(contact => contact.contact_type === ContactType.PRIMARY);
                if (!this.$helper.isBlank(major_contact)) {
                    return major_contact.email;
                }
            }

            return '';
        },

        // 登録処理
        save() {
            if (!this.$refs.form.reportValidity()) {
                return;
            }

            this.startScreenLoading();

            this.$http.post(`/project/${this.project.project_id}/activity`, this.activity_to_create)
            .then(response => {
                this.$emit('save', response.data);
                this.showMessage('活動履歴を登録しました');

                // アプローチ記録にリセット
                this.is_active = ActivityType.APPROACH;
                this.activity_to_create = new Activity();
            })
            .finally(() => {
                this.endScreenLoading();
            })
        },

        // 郵便番号で住所を検索して上書き
        searchAddressByZip() {
            if (!this.activity_to_create.zip_code) {
                return;
            }

            // $http がつくと不要なヘッダがついて cors で弾かれるので、生 axios 使用
            axios.get('https://zipcloud.ibsnet.co.jp/api/search?zipcode=' + this.activity_to_create.zip_code)
            .then(response => {
                if (response.data.results && response.data.results.length > 0) {
                    let record = response.data.results[0];
                    this.activity_to_create.area = new Area({
                        area_id: record.prefcode,
                        area_name: record.address1,
                    });
                    this.activity_to_create.city = record.address2;
                    this.activity_to_create.street = record.address3;
                }
            });
        }
    },
}
</script>

<style scoped>
    .form-check-label:hover {cursor: pointer;}
</style>